;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"f0115fbd5ecd3ed7509403129c23dc18ea11046e"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import { Sentry } from './instrumentation';
import { sharedConfig } from './sentry.shared.config';

Sentry.init({
  ...sharedConfig,
  integrations: [Sentry.replayIntegration()],
  replaysSessionSampleRate:
    process.env.NEXT_PUBLIC_REPLAYS_SESSION_SAMPLE_RATE || 0.0,
  replaysOnErrorSampleRate:
    process.env.NEXT_PUBLIC_REPLAYS_ON_ERROR_SAMPLE_RATE || 0.0,
});
